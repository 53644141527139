<template>
  <v-footer color="secondary">
    <v-container>
      <v-row justify="center" no-gutters>
        <v-btn
          text
          rounded
          v-for="{ url, text } in buttonRow"
          :key="url"
          v-text="text"
          :href="url"
          target="_blank"
        />

        <PrivacyDialog :impressumUrl="impressumUrl">
          <template v-slot:default="{ on, attrs }">
            <v-btn text rounded v-on="on" v-bind="attrs">Datenschutz</v-btn>
          </template>
        </PrivacyDialog>
      </v-row>

      <v-row
        v-if="showFooterLogos && imgRow && imgRow.length > 0"
        justify="center"
        align="center"
        class="mt-6"
      >
        <a
          v-for="{ url, text, image, classes } in imgRow"
          :key="url"
          :href="url"
          target="_blank"
          class="ma-6"
        >
          <div v-if="text" v-text="text" />
          <img :src="image" :class="classes" style="max-height: 180px" />
        </a>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Vue from "vue";
import PrivacyDialog from "./PrivacyDialog.vue";
import { storeToRefs } from "pinia";
import { useConfigStore } from "../store/config-store";

export default Vue.extend({
  name: "Footer",

  components: {
    PrivacyDialog,
  },

  props: {
    frontCfg: {
      type: Object,
      default: () => ({
        footerElements: [],
        colorOfFooterCRlogo: "white",
      }),
    },
  },

  data: () => ({
    showPrivacy: false,
  }),
  computed: {
    showFooterLogos() {
      console.log("storeToRefs(useConfigStore()).showFooterLogos;=",storeToRefs(useConfigStore()).showFooterLogos)
      return storeToRefs(useConfigStore()).showFooterLogos;
    },
    buttonRow() {
      return this.frontCfg.footerElements?.filter((l) => !l.image) || [];
    },
    imgRow() {
      let imgPath;
      let imgRow = [];
      /* depending on the color set in the config for the footer CR logo adapt the path of the logo: it has to be 
      stored in the config-directory of the instance. Default is white-Logo */
      if (this.frontCfg.colorOfFooterCRlogo === "white") {
        imgPath = "./cleverroute-logo-white.png";
      } else if (this.frontCfg.colorOfFooterCRlogo === "green") {
        imgPath = "./cleverroute-logo-green.png";
      } else {
        imgPath = "./cleverroute-logo-white.png";
      }
      // set up the object for the CR logo with the link if it is set in the config
      if (imgPath) {
        imgRow = imgRow.concat([
          {
            url: "https://ecolibro.de/Tools/cleverroute",
            image: imgPath,
            classes: "shadowed",
          },
        ]);
      }
      return imgRow.concat(
        ...(this.frontCfg.footerElements?.filter((l) => !!l.image) || [])
      );
    },
    impressumUrl() {
      return this.frontCfg.footerElements?.find((l) => l.text === "Impressum")
        ?.url;
    },
  },
});
</script>

<style scoped>
.shadowed {
  filter: drop-shadow(-2px 4px 3px rgba(0, 0, 0, 0.33));
}

.h160 {
  height: 160px;
}
.h140 {
  height: 140px;
}
.h120 {
  height: 120px;
}
.h100 {
  height: 100px;
}
.h80 {
  height: 80px;
}
</style>
