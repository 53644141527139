<template>
  <div class="flex-box-center-items-vertical">
    <div
      class="flex-box-right-aligned-horizontal"
      style="align-items: flex-end"
    >
      <div style="margin-right: 5px">Bitte geben Sie ihre Postleitzahl an</div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }"
          ><v-icon
            :color="$vuetify.theme.themes.light.secondary"
            size="dense"
            dark
            v-bind="attrs"
            v-on="on"
            >mdi-help-circle</v-icon
          ></template
        >
        <span>
          Die Daten werden anonym gespeichert. Es wird nur die PLZ übermittelt,
          nicht die vollständige Adresse. Es ist anhand der Angaben kein
          Rückschluss auf Sie als Person möglich.
        </span>
      </v-tooltip>
      <input
        :value="pollPlz()"
        @change="pollPlzChanged"
        :style="colorOfInputElementsStyle"
        style="
          margin-left: 10px;
          margin-right: 10px;
          padding-left: 5px;
          width: 100px;
        "
      />
      <v-btn :disabled="!isPollValid || isSubmitted" @click="submitPoll()">
        {{ submitButtonText }}</v-btn
      >
    </div>
    <span
      v-if="
        useExternalPollLink && externalPollLink && isPollValid && isSubmitted
      "
      style="color: red; align-self: end"
      >Hier klicken, falls kein Link geöffnet wurde:
      <a target="_blank" :href="externalPollLink">{{
        externalPollLink
      }}</a></span
    >
    <span v-if="!isPollPlzValid()" style="color: red; align-self: end"
      >Postleitzahl ungültig!</span
    >
    <span v-if="!isFrequenciesValid()" style="color: red; align-self: end"
      >Gesamthäufigkeit der Transportvarianten ungültig!</span
    >
    <span v-if="!isTransportsUsedValid()" style="color: red; align-self: end"
      >Bitte wählen Sie genau eine Option aus der obenstehenden Liste aus!</span
    >

    <span
      :style="{
        color:
          isSubmitted && submitText
            ? $vuetify.theme.themes.light.secondary
            : submitText
            ? 'red'
            : '',
      }"
      v-if="submitText"
      >{{ submitText }}</span
    >
  </div>
</template>
<script>
import { usePollStore } from "../../store/poll-store";
import { storeToRefs } from "pinia";
import Vue from "vue";
import { fetchUserPollId, savePollToDB } from "../../api";
import * as utils from "../../utils";
import { Poll } from "../../jm-api-client/src";

export default Vue.extend({
  name: "PollFooter",
  components: {},
  props: {
    routes: { type: Array, default: () => [] },
  },

  data: () => ({
    isSubmitted: false,
    submitText: "",
    pollValid: false,
    externalPollLink: undefined
}),

  mounted() {
    // if (this.useExternalPollLink && this.externalPollLink) {
    //   const pollType = storeToRefs(usePollStore()).pollType.value;
    //   const pollDate = storeToRefs(usePollStore()).pollDate.value;
    //   const clientName = storeToRefs(usePollStore()).clientName.value;
    //   fetchUserPollId(clientName, pollDate, pollType)
    //     .then((res) => {
    //       this.pollUserId = res;
    //     })
    //     .catch((err) => console.log("Error while fetching user-id: ", err));
    // }
  },

  methods: {
    pollPlz() {
      const store = usePollStore();
      return storeToRefs(store).pollPlz.value;
    },
    isPollPlzValid() {
      return usePollStore().isPollPlzValid();
    },
    isFrequenciesValid() {
      return usePollStore().isPollFrequenciesValid();
    },
    isTransportsUsedValid() {
      return usePollStore().isTransportsUsedValid();
    },
    pollPlzChanged(event) {
      const plzStr = event.target.value;
      usePollStore().setPlz(plzStr);
      // this.updatePollPlz(plzStr);
    },
    async submitPoll() {
      let routesPoll = this.routes.map((route, i) => ({
        ...route,
        frequency: usePollStore().pollFrequency(i),
        nPassengers: usePollStore().numberPassengersOfTransport(i),
        transportUsed: usePollStore().isTransportUsed(i),
        nStars: usePollStore().getNumberStars(i),
      }));
      const poll = {
        pollType: storeToRefs(usePollStore()).pollType.value,
        plz: storeToRefs(usePollStore()).pollPlz.value,
        pollDate: storeToRefs(usePollStore()).pollDate.value,
        clientName: storeToRefs(usePollStore()).clientName.value,
        employeeId: -1,
        monthsInOrg: Number(
          storeToRefs(usePollStore()).pollMonthsInOrganization.value
        ),
        monthsInOrgMax: Number(
          storeToRefs(usePollStore()).pollMonthsInOrganizationMax.value
        ),
        workdaysPerWeek: Number(
          storeToRefs(usePollStore()).pollWorkingDays.value
        ),
        workdaysPerWeekMax: Number(
          storeToRefs(usePollStore()).pollWorkingDaysMax.value
        ),
        homeofficeDaysPerWeek: Number(
          storeToRefs(usePollStore()).pollHomeofficeDays.value
        ),
        homeofficeDaysPerWeekMax: Number(
          storeToRefs(usePollStore()).pollHomeofficeDaysMax.value
        ),
        routes: routesPoll,
      };

      let poll_obj = Poll.constructFromObject(poll);
      let res;
      try {
        res = await savePollToDB(poll_obj);
      } catch {
        this.submitText =
          "Leider ist ein Fehler bei der Übertragung aufgetreten.\n Bitte versuch es später wieder!";
      }
      if (res.status) {
        // if there is an external poll link provided and
        if (!this.useExternalPollLink || !res.data?.userPollId) {
          this.submitText =
            "Vielen Dank für deine Teilnahme! Die Daten wurden erfolgreich gespeichert!";
        }
        this.isSubmitted = true;
      } else {
        this.submitText =
          "Leider ist ein Fehler bei der Übertragung aufgetreten.\n Bitte versuch es später wieder!";
      }
      if (this.useExternalPollLink && res.data?.userPollId) {
        let pollLink = storeToRefs(usePollStore()).externalPollLink.value;
        if (res.data?.userPollId) {
          pollLink += "?r=" + res.data?.userPollId;
        }
        this.externalPollLink = pollLink;
      }

      // usePollStore().resetSuveyInput();
      // if external poll link is desired to be shown and it exists, then open it in new tab
      if (this.useExternalPollLink && this.externalPollLink) {
        window.open(this.externalPollLink, "_blank");
      }

      // console.log("mySavedPolls=", res);
    },
    // connectToApi(poll) {
    //   var defaultClient = ApiClient.instance;

    //   // Configure API key authorization: apiKey
    //   var apiKey = defaultClient.authentications["apiKey"];
    //   apiKey.apiKey = "abc12341";
    //   // Uncomment the following line to set a prefix for the API key, e.g. "Token" (defaults to null)
    //   //apiKey.apiKeyPrefix['apiKey'] = "Token"
    //   const pollApi = new PollApi(defaultClient);
    //   const aApi = new AdressesApi(defaultClient);
    //   aApi.postAdress({street: "test-street"});

    //   // pollApi
    //   //   .writePoll({body: {}})
    //   //   .then((res) => console.log("Response=", res))
    //   //   .catch((err) => console.log("Error: ", err));

    //   // var callback = function(error, data, response) {
    //   //   if (error) {
    //   //     console.error(error);
    //   //   } else {
    //   //     console.log('API called successfully. Returned data: ' + data);
    //   //   }
    //   // };
    //   // api.deleteAdress(adId, callback);
    // },
  },

  computed: {
    submitButtonText() {
       let pollLink = storeToRefs(usePollStore()).externalPollLink.value;
      return this.useExternalPollLink && pollLink
        ? "Weiter zur nächsten Frage"
        : "absenden";
    },
    useExternalPollLink() {
      return storeToRefs(usePollStore()).useExternalPollLink.value;
    },
    // externalPollLink() {
    //   let pollLink = storeToRefs(usePollStore()).externalPollLink.value;
    //   if (this.userPollId) {
    //     pollLink += "?userPollId=" + this.userPollId;
    //   }
    //   return pollLink;
    // },
    isPollValid() {
      return usePollStore().isPollValid(); //isPollPlzValid();
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
  },

  watch: {},
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
